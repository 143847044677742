import React, { useContext } from 'react';
import { BsCheckCircleFill } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';

import { AdminContext } from '../../../contexts/admin.context';

export default function SlideList({ items }) {
  const { i18n } = useTranslation();

  const { themeConfig: { primary } = {} } = useContext(AdminContext);

  return (
    <div className="shadow-custom1 my-6 bg-white rounded-md p-4 mx-auto">
      <ul className="text-left">
        {items.map((item) => {
          return (
            <li key={item._id} className="mb-2">
              <div className="flex items-center justify-between gap-2">
                <span>{item?.[`name_${i18n.language}`]}</span>
                <span>
                  <BsCheckCircleFill color={primary} />
                </span>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
