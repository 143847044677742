import { useState, useContext, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { AdminContext } from '../contexts/admin.context';
import { useTranslation } from 'react-i18next';

import InputSelectStudent from './common/InputSelectStudent';
import FullScreenLoader from './common/fullScreenLoader';

import {
  setAuthToken,
  setOrgHeaders,
  psyfiersAxios,
  getStudentProfile,
  getStudentProfileFromMFDB
} from '../service';

import { getTranslation } from '../util/translation';
import Button from './ui/button';

const DashboardLayout = () => {
  const navigate = useNavigate();

  const { i18n, t } = useTranslation();

  const [languageOptions, setLanguageOptions] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const {
    themeId,
    teamType,
    studentProfile,
    setStudentProfile,
    portalConfig: { basePath, languages } = {}
  } = useContext(AdminContext);

  const handleChange = (lang) => {
    setSelectedLanguage(lang);
    i18n.changeLanguage(lang?.value || lang);

    try {
      localStorage?.setItem('lang', lang?.value || lang);
    } catch (error) {
      console.log('LS unavailable');
    }
  };

  const handleLogout = () => {
    try {
      localStorage?.removeItem('token');
    } catch (error) {
      console.log('error', error);
    }
    navigate(basePath + '/login');
  };

  // Get logged user data
  useEffect(() => {
    try {
      try {
        const token = localStorage.getItem('token');
        setAuthToken(token);
      } catch (error) {
        console.log('LS unavailable');
      }

      const token = psyfiersAxios.defaults.headers.common['Authorization'];
      if (!token || token === 'Bearer null') throw new Error('No token found');

      setOrgHeaders(themeId, teamType);

      (async () => {
        try {
          try {
            if (!studentProfile) {
              const { data } = await getStudentProfile();
              const { data: mfData } = await getStudentProfileFromMFDB({
                orgPrefix: themeId,
                teamType,
                accountID: data?.account_id,
                projectID: data?.project_id,
                tanID: data?.id
              });

              setStudentProfile({ ...data });

              if (mfData?.data?.firstName) {
                setStudentProfile({ ...data, firstname: mfData?.data?.firstName });
              }
            }
          } catch (error) {
            console.log('error', error);
            navigate(basePath + '/login');
          }

          const options = languages?.map((lang) => ({
            label: lang?.toUpperCase(),
            value: lang
          }));
          setLanguageOptions(options);

          let lastLanguage = languages?.[0];

          try {
            lastLanguage = localStorage?.getItem('lang')
              ? localStorage?.getItem('lang')
              : lastLanguage;
          } catch (error) {
            console.log('LS unavailable');
          }

          const setLanguage = languages?.includes(lastLanguage)
            ? { label: lastLanguage.toUpperCase(), value: lastLanguage }
            : options?.[0];

          setSelectedLanguage(setLanguage);
          i18n.changeLanguage(setLanguage?.value);
        } catch (error) {
          console.log('error', error);
          navigate(basePath + '/login');
        } finally {
          setIsLoading(false);
        }
      })();
    } catch (e) {
      console.log('error', e);
      navigate(basePath + '/login');
    }
  }, []);

  if (isLoading) return <FullScreenLoader />;

  return (
    <>
      <div className="container mx-auto min-h-screen overflow-hidden bg-white p-10">
        <div className="flex flex-row items-center justify-end gap-5 w-full mb-12">
          {languageOptions?.length > 1 && (
            <InputSelectStudent
              value={selectedLanguage}
              options={languageOptions}
              onChange={handleChange}
              containerClassName={'!m-0'}
              className={'!py-2 !m-0'}
            />
          )}

          <Button.ButtonOutlined handler={handleLogout}>
            <span dangerouslySetInnerHTML={{ __html: getTranslation(t, 'logoutButton') }} />
          </Button.ButtonOutlined>
        </div>
        {/* MAIN LAYOUT */}
        <div className="relative">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;
