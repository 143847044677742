import React, { useContext } from 'react';
import Select from 'react-select';

import { AdminContext } from '../../contexts/admin.context';

export default function InputSelectStudent({
  className,
  value,
  label,
  error,
  touched,
  options,
  onChange = () => {}
}) {
  const { themeConfig: { primary, secondary, buttonRadius } = {} } = useContext(AdminContext);

  return (
    <div>
      {label && (
        <label className="block mb-1 text-base font-bold text-cblack-primary">{label}</label>
      )}
      <Select
        value={value}
        styles={{
          control: (base) => ({
            ...base,
            borderRadius: buttonRadius,
            border: `1px solid ${primary}`,
            boxShadow: 'none',
            '&:hover': {
              borderColor: primary
            },
            '&:focus': {
              borderColor: primary
            }
          }),
          indicatorSeparator: (provided, state) => ({
            ...provided,
            display: 'none' // Remove the line between icon and label
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            borderColor: primary,
            borderRadius: buttonRadius
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? primary : 'white',
            color: state.isSelected ? 'white' : 'black',
            '&:hover': {
              backgroundColor: secondary,
              color: 'white'
            }
          })
        }}
        className={`mb-4 rounded-full ${className}`}
        options={options}
        onChange={(value) => onChange(value)}
      />
      {error && touched && <p className="text text-red-500">{error}</p>}
    </div>
  );
}
