import React, { useState, useContext } from 'react';
import OtpInput from 'react18-input-otp';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AdminContext } from '../../contexts/admin.context';

import Button from '../../components/ui/button';
import { ProgresLoader } from '../../components';

import { loginStudent, setAuthToken } from '../../service';

import { getTranslation } from '../../util/translation';

export default function StudentLogin() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [vipCode, setVipCode] = useState('');
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [progress, setProgress] = useState(0);

  const {
    portalConfig: { basePath } = {},
    themeConfig: { primary } = {},
    setStudentProfile
  } = useContext(AdminContext);

  const onChangeVipCode = (code) => {
    setVipCode(code);
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();

    try {
      setProgress(0);
      setShowLoadingPopup(true);
      if (vipCode.length !== 6) throw new Error(getTranslation(t, 'error.login.invalidVipCode'));

      const {
        data: { token, student: data }
      } = await loginStudent({ tan: vipCode });

      if (data.active) toast.error(getTranslation(t, 'error.login.nonActive'));
      else {
        try {
          localStorage.setItem('token', token);
        } catch (error) {
          console.log('LS unavailable', error);
        }

        setAuthToken(token);
        setStudentProfile({ ...data });
        navigate(basePath + '/student-profile-1');
      }
    } catch (e) {
      const message = e.response?.data?.message || e.message;
      toast.error(message);
      console.log('error', e.message);
    } finally {
      setProgress(100);
    }
  };

  if (showLoadingPopup)
    return (
      <ProgresLoader
        progress={progress}
        setProgress={setProgress}
        setLoading={setShowLoadingPopup}
      />
    );

  return (
    <section
      data-testid="login-form"
      className="flex flex-col items-center justify-center min-h-screen">
      <div className="p-6 sm:p-28">
        <h1
          className="text-2xl font-bold text-cblack-default"
          dangerouslySetInnerHTML={{
            __html: getTranslation(t, 'class.student.student_login_page.title1')
          }}
        />
        <form onSubmit={onFormSubmit}>
          <div className="my-6">
            <h2
              className="font-semibold text-cblack-default text-lg"
              dangerouslySetInnerHTML={{
                __html: getTranslation(t, 'class.student.student_login_page.title2')
              }}
            />
            <p
              className="text-cgrey-100"
              dangerouslySetInnerHTML={{
                __html: getTranslation(t, 'class.student.student_login_page.description')
              }}
            />
          </div>
          <OtpInput
            data-testid="vip-code-input"
            inputStyle="otp-input-style"
            focusStyle="otp-focus-style"
            isInputNum={false}
            shouldAutoFocus
            containerStyle="otp-code-box"
            numInputs={6}
            value={vipCode}
            onChange={onChangeVipCode}
          />
          <div className="w-full h-[1px] my-6 bg-black"></div>
          <div className="text-center mt-36 md:mt-0">
            <Button.ButtonBasic
              type="submit"
              disabled={vipCode.length !== 6 || showLoadingPopup}
              loading={showLoadingPopup}
              className={`mx-auto bg-[${primary}]`}
              data-testid="submit-button">
              <span
                dangerouslySetInnerHTML={{
                  __html: getTranslation(t, 'class.student.student_login_page.login_button')
                }}
              />
            </Button.ButtonBasic>
          </div>
        </form>
      </div>
    </section>
  );
}
