import React, { useContext } from 'react';
import { FaArrowRight } from 'react-icons/fa';

import { AdminContext } from '../../../contexts/admin.context';

import { Spinner } from '../../common';

export default function ButtonNext({ title, type, handler, className, loading = false, disabled }) {
  const { themeConfig: { primary, buttonRadius } = {} } = useContext(AdminContext);

  return (
    <button
      type={type}
      className={`px-4 py-2 disabled:opacity-60 ${className} flex flex-row gap-2 items-center justify-center text-white`}
      style={{ backgroundColor: primary, borderRadius: buttonRadius }}
      onClick={handler}
      disabled={loading || disabled}>
      <span dangerouslySetInnerHTML={{ __html: title }} />
      <FaArrowRight className="icon" />
      <Spinner isSubmitting={loading} />
    </button>
  );
}

ButtonNext.defaultProps = {
  className: '',
  type: ''
};
