import React, { useContext, useEffect, useState } from 'react';
import { HiArrowNarrowLeft, HiArrowNarrowRight } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Carousel from 'nuka-carousel';
import { isEmpty } from 'lodash';
import { AdminContext } from '../../../contexts/admin.context';

import ButtonNext from '../../../components/ui/button/ButtonNext';
import { FloatingBackButton } from '../../../components';
import StepBar from '../../../components/common/StepBar';
import Slide1 from './Slide1';
import Slide2 from './Slide2';
import Slide3 from './Slide3';
import Slide4 from './Slide4';

import { addNewStudent, generateStudentPDFURL, storeStats } from '../../../service';
import { useTranslation } from 'react-i18next';
import { getTranslation } from '../../../util/translation';
import { downloadPDFWithURL } from '../../../util/downloads';

export default function StudentProfileStep4() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);

  const {
    themeId,
    teamType,
    projectID: orgId,
    studentProfile,
    themConfig: { primary } = {},
    portalConfig: { basePath, studentPortalSetup: { downloadStudentProfiles } = {} } = {}
  } = useContext(AdminContext);

  useEffect(() => {
    if (isEmpty(studentProfile.roles)) navigate(basePath + '/student-profile-3');
  }, [studentProfile]);

  const generateStudentPDF = async (tanID, projectID, accountID) => {
    try {
      const { data } = await generateStudentPDFURL({
        orgPrefix: themeId,
        teamType,
        accountID,
        projectID,
        tanID,
        lang: i18n.language
      });

      return data?.URL;
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onGoBack = () => {
    navigate(basePath + '/student-profile-3');
  };

  const handleSubmit = async () => {
    try {
      setIsGeneratingPDF(true);
      const {
        roles: tempRoles,
        strengths: tempStrengths,
        project_id: projectID,
        firstname: firstName = '',
        active,
        id: tanID,
        account_id: accountID,
        email = '',
        image,
        language
      } = studentProfile;

      const roles = tempRoles.map(({ _id }) => _id);
      const strengths = tempStrengths.map(({ _id }) => _id);

      await addNewStudent({
        orgPrefix: themeId,
        teamType,
        projectID,
        firstName: firstName ? firstName : '',
        active,
        tanID,
        accountID,
        strengths,
        roles,
        image,
        email,
        language
      });

      try {
        for (let selectedStrength of tempStrengths) {
          await storeStats({
            application: 'student',
            event: 'student_chosen_strength',
            orgPrefix: themeId,
            teamType: teamType,
            pageURL: window?.location?.pathname,
            pageTitle: document?.title,
            accountID,
            projectID,
            tanID,
            championName: firstName,
            strengthName: selectedStrength[`name_${i18n.language}`]
          });
        }
      } catch (error) {
        console.error('Error in storing stats for selected strengths');
      }

      try {
        for (let selectedRole of tempRoles) {
          await storeStats({
            application: 'student',
            event: 'student_chosen_role',
            orgPrefix: themeId,
            teamType: teamType,
            pageURL: window?.location?.pathname,
            pageTitle: document?.title,
            accountID,
            projectID,
            tanID,
            championName: firstName,
            roleName: selectedRole[`name_${i18n.language}`]
          });
        }
      } catch (error) {
        console.error('Error in storing stats for selected roles');
      }

      if (downloadStudentProfiles?.enabled) {
        try {
          const pdfLink = await generateStudentPDF(tanID, projectID, accountID);
          downloadPDFWithURL(pdfLink, `${firstName}-${tanID}.pdf`);
        } catch (error) {
          toast.error(getTranslation(t, 'error.pdfGeneration'));
        }
        return;
      }
      window.location.href = `https://www.next.mission-future.com/${themeId}/${teamType}/${accountID}/${projectID}/student/${tanID}`;
    } catch (e) {
      const message = e.response?.data?.message || 'something went wrong';
      console.log('error', e.message);
      toast.error(message);
    } finally {
      setIsGeneratingPDF(false);
    }
  };

  return (
    <section>
      <FloatingBackButton onGoBack={onGoBack} />
      <div className="max-w-2xl w-full mt-12 mx-auto">
        <StepBar className="w-full" stepsClass="w-full" activeStep={4} totalSteps={4} />

        <div className="text-center my-6">
          <h1
            className="text-2xl font-bold"
            dangerouslySetInnerHTML={{
              __html: getTranslation(t, 'class.student.student_login_data.page_4.step_4')
            }}
          />
          <p
            className="text-lg mb-4"
            dangerouslySetInnerHTML={{
              __html: getTranslation(t, 'class.student.student_login_data.page_4.description')
            }}
          />

          <Carousel
            wrapAround={true}
            slidesToShow={1}
            renderBottomCenterControls={false}
            renderCenterLeftControls={({ previousDisabled, previousSlide }) => (
              <button
                className={`-mr-2 md:ml-0 border-2 rounded-full p-2 text-lg`}
                style={{ borderColor: primary, color: primary }}
                onClick={previousSlide}
                disabled={previousDisabled}>
                <HiArrowNarrowLeft />
              </button>
            )}
            renderCenterRightControls={({ nextDisabled, nextSlide }) => (
              <button
                className={`-mr-2 md:mr-0 border-2 rounded-full p-2 text-lg`}
                style={{ borderColor: primary, color: primary }}
                onClick={nextSlide}
                disabled={nextDisabled}>
                <HiArrowNarrowRight />
              </button>
            )}>
            <Slide1
              image={studentProfile.image}
              title={getTranslation(t, 'class.student.student_login_data.page_4.slide_1')}
              buttonText={getTranslation(
                t,
                'class.student.student_login_data.page_4.button_content1'
              )}
            />
            <Slide2
              forces={studentProfile.strengths}
              title={getTranslation(t, 'class.student.student_login_data.page_4.slide_2')}
              buttonText={getTranslation(
                t,
                'class.student.student_login_data.page_4.button_content1'
              )}
            />
            <Slide3
              roles={studentProfile.roles}
              title={getTranslation(t, 'class.student.student_login_data.page_4.slide_3')}
              buttonText={getTranslation(
                t,
                'class.student.student_login_data.page_4.button_content1'
              )}
            />
            {/* <Slide4
              language={studentProfile.language}
              // title={getTranslation(t, 'class.student.student_login_data.page_4.slide_3')}
              title={'Prefered Language'}
              buttonText={getTranslation(
                t,
                'class.student.student_login_data.page_4.button_content1'
              )}
            /> */}
          </Carousel>

          <div className="text-center my-6">
            <ButtonNext
              title={getTranslation(t, 'class.student.student_login_data.page_4.button_content2')}
              className="mx-auto"
              handler={handleSubmit}
              loading={isGeneratingPDF}
              disabled={isGeneratingPDF}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
