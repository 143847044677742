import ButtonNext from './ButtonNext';
import ButtonOutlined from './ButtonOutlined';
import ButtonBasic from './ButtonBasic';

const Button = {
  ButtonNext,
  ButtonOutlined,
  ButtonBasic
};

export default Button;
