export const downloadFile = (data, fileName) => {
  try {
    const href = URL.createObjectURL(data);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  } catch (error) {
    // Normally download with URL
    const link = document.createElement('a');
    link.href = data;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
  }
};

export const downloadPDFWithURL = async (url, fileName) => {
  try {
    const parsedURL = JSON.parse(url) || url;
    const response = await fetch(parsedURL);
    const content = await response.blob();
    downloadFile(content, fileName);
  } catch (error) {
    try {
      const response = await fetch(url);
      const content = await response.blob();
      downloadFile(content, fileName);
    } catch (error) {
      console.error(error);
    }
    console.error(error);
  }
};
