import React, { useContext } from 'react';
import SlideContainer from './SlideContainer';

import { AdminContext } from '../../../contexts/admin.context';

export default function Slide1({ image, title = 'Icône préférée', buttonText = 'Modifier' }) {
  const { themeConfig: { primary } = {}, portalConfig: { basePath } = {} } =
    useContext(AdminContext);

  return (
    <SlideContainer title={title} buttonText={buttonText} link={basePath + '/student-profile-1'}>
      <div
        className={`md:w-48 md:h-48 my-6 overflow-hidden rounded-full mx-auto  p-2`}
        style={{ background: `linear-gradient(180deg, #E2E2E2, ${primary})` }}>
        <img src={image} title={title} className="w-full h-full object-fit" />
      </div>
    </SlideContainer>
  );
}
