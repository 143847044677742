import React, { useState, useEffect, useContext } from 'react';
import { BsCheckCircleFill } from 'react-icons/bs';
import { BsFillCircleFill } from 'react-icons/bs';
import { BiSearchAlt2 } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';

import { AdminContext } from '../../../contexts/admin.context';

export default function DropdownListSelector({
  placeholder = 'Rechercher',
  options,
  selectedOption = [],
  onClick
}) {
  const { i18n } = useTranslation();

  const [optionsList, setOptionsList] = useState(options);

  const { themeConfig: { primary } = {} } = useContext(AdminContext);

  const searchInList = (value) => {
    if (!value) {
      setOptionsList(options);
      return;
    }

    const temp = [...options].filter((option) =>
      option?.[`name_${i18n.language}`]?.toLowerCase()?.includes(value?.toLowerCase())
    );

    setOptionsList(temp);
  };

  useEffect(() => {
    setOptionsList(options);
  }, [options]);

  return (
    <div>
      <div className="relative">
        <span className="absolute top-3 left-2">
          <BiSearchAlt2 className="text-xl" />
        </span>
        <input
          className="border-2 border-cwhite-500 w-full px-8 py-2 rounded bg-cwhite-500"
          placeholder={placeholder}
          onChange={(e) => searchInList(e.target.value)}
        />
      </div>
      <div className="h-56 bg-cwhite-500 rounded overflow-y-scroll my-4 px-4">
        <ul className="">
          {optionsList.map((item, index) => {
            let selected = <BsFillCircleFill className="text-cwhite-600" />;
            let isSelected = selectedOption.find((option) => {
              if (option._id === item._id) {
                return true;
              }
              return false;
            });
            if (isSelected) {
              selected = <BsCheckCircleFill color={primary} />;
            }
            return (
              <li
                key={index}
                className="border-b-2 border-cgwhite-500"
                onClick={() => {
                  onClick(item);
                }}>
                <div className="flex justify-between iBsFillCircleFilltems-center p-2 cursor-pointer">
                  <span>{item?.[`name_${i18n.language}`]}</span>
                  <span>{selected}</span>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
