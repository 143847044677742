import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { AdminContext } from '../../contexts/admin.context';

import { DropdownListSelector, FloatingBackButton, IconImageBadge } from '../../components/common';
import StepBar from '../../components/common/StepBar';
import ButtonNext from '../../components/ui/button/ButtonNext';
import { DropdownListDisplay } from '../../components/common/DropdownListSelector';

import { getTranslation } from '../../util/translation';

export default function StudentProfileStep2() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const {
    portalConfig: { basePath, strengths } = {},
    studentProfile,
    setStudentProfile
  } = useContext(AdminContext);

  const [selectedStrengths, setSelectedStrengths] = useState([]);
  const [showFinalList, setShowFinalList] = useState(false);

  useEffect(() => {
    if (isEmpty(studentProfile.image)) navigate(basePath + '/student-profile-1');

    if (studentProfile.strengths) {
      setSelectedStrengths(studentProfile.strengths);
      setShowFinalList(true);
    }
  }, [studentProfile]);

  const onContinue = () => {
    setStudentProfile({ ...studentProfile, strengths: selectedStrengths });
    navigate(basePath + '/student-profile-3');
  };

  const onChangeForceSelections = (option) => {
    const index = selectedStrengths.findIndex((item) => item._id === option._id);
    if (index === -1 && selectedStrengths.length < 3) {
      setSelectedStrengths([...selectedStrengths, option]);
      if (selectedStrengths.length === 2) {
        setShowFinalList(true);
      }
    } else if (index !== -1) {
      const updatedSelections = [...selectedStrengths];
      updatedSelections.splice(index, 1);
      setSelectedStrengths(updatedSelections);
      setShowFinalList(false);
    }
  };

  const onShowDropdown = () => {
    setShowFinalList(!showFinalList);
  };

  const onGoBack = () => {
    navigate(basePath + '/student-profile-1');
  };

  console.log(selectedStrengths);

  return (
    <section>
      <FloatingBackButton className="block" onGoBack={onGoBack} />

      <div className="max-w-md mx-auto">
        <StepBar className="w-full my-8" stepsClass="w-full" activeStep={2} />

        <h2
          className="text-xl md:text-2xl font-semibold text-black-default"
          dangerouslySetInnerHTML={{
            __html: getTranslation(t, 'class.student.student_login_data.page_2.step_2')
          }}
        />
        <p
          className="text-sm md:text-lg text-cblack-default"
          dangerouslySetInnerHTML={{
            __html: getTranslation(t, 'class.student.student_login_data.page_2.description')
          }}
        />

        <div className="my-4">
          {!showFinalList && (
            <DropdownListSelector
              options={strengths}
              onClick={onChangeForceSelections}
              selectedOption={selectedStrengths}
            />
          )}

          {showFinalList && (
            <div className="my-6 bg-cwhite-200 rounded-lg p-4 mx-auto">
              <DropdownListDisplay list={selectedStrengths} onClick={onShowDropdown} />
            </div>
          )}

          {showFinalList && (
            <div className="text-left my-6">
              <ButtonNext
                title={getTranslation(t, 'class.student.student_login_data.page_2.button_content')}
                className="ml-auto mr-auto md:ml-0 md:mr-auto"
                handler={onContinue}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
