import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty, find } from 'lodash';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Popup from 'reactjs-popup';
import { AdminContext } from '../../contexts/admin.context';

import { IconImageBadge } from '../../components/common';
import StepBar from '../../components/common/StepBar';
import ButtonNext from '../../components/ui/button/ButtonNext';

import { getClassImages } from '../../service';
import { getTranslation } from '../../util/translation';
import { BiEdit } from 'react-icons/bi';

export default function StudentProfileStep1() {
  const navigate = useNavigate();

  const [profileImage, setProfileImage] = useState('');
  const [inUseImages, setInUseImages] = useState([]);
  const [userData, setUserData] = useState(null);
  const [editName, setEditName] = useState(false);

  const {
    themeId,
    teamType,
    studentProfile,
    setStudentProfile,
    portalConfig: { basePath, studentPortalSetup: { editNameInPortal } = {} } = {}
  } = useContext(AdminContext);

  const { t } = useTranslation();

  const changeProfileImage = (image) => {
    setProfileImage(image);
  };

  const getPageData = async () => {
    try {
      const { id, account_id, project_id } = studentProfile;

      const {
        data: { data, userData }
      } = await getClassImages({
        orgPrefix: themeId,
        teamType,
        tan: id,
        account: account_id,
        project: project_id
      });

      setInUseImages(data);
      setUserData(userData);
    } catch (e) {
      toast.error('Aucun projet trouvé !!');
      console.log('error:', e.message);
    }
  };

  useEffect(() => {
    getPageData();

    if (studentProfile.image) setProfileImage(studentProfile.image);
  }, [studentProfile]);

  const onContinue = () => {
    navigate(basePath + '/student-profile-2');
    setStudentProfile({ ...studentProfile, image: profileImage });
  };

  const isDisabledImage = (imageURL) => {
    const imageObj = find(inUseImages, { image: imageURL });
    return imageObj?._id && imageObj?._id !== userData?._id;
  };

  return (
    <section>
      <>
        <StepBar className="w-full my-8" stepsClass="w-fit" activeStep={1} />

        <h2
          className="text-xl md:text-2xl font-semibold text-black-default"
          dangerouslySetInnerHTML={{
            __html: getTranslation(t, 'class.student.student_login_data.page_1.step_1')
          }}
        />
        <p
          className="text-sm md:text-lg text-cblack-default"
          dangerouslySetInnerHTML={{
            __html: getTranslation(t, 'class.student.student_login_data.page_1.description')
          }}
        />
      </>

      <div className="flex flex-col gap-3 sm:gap-0 sm:flex-row items-center">
        <div className="w-full md:w-3/5">
          <div className="shadow-custom rounded-3xl flex flex-wrap gap-2 px-4 py-6 justify-center mt-6">
            {new Array(50).fill(0).map(({}, index) => {
              let imageURL = `/images/profile-icons/Intersect${index === 0 ? '' : '-' + index}.png`;
              const isDisabled = isDisabledImage(imageURL);
              return (
                <img
                  key={index}
                  src={imageURL}
                  className={`h-8 md:h-14 w-8 md:w-14 cursor-pointer ${
                    isDisabled ? 'opacity-30 cursor-not-allowed' : ''
                  }`}
                  style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
                  onClick={() =>
                    !isDisabled &&
                    changeProfileImage(
                      `/images/profile-icons/Intersect${index === 0 ? '' : '-' + index}.png`
                    )
                  }
                />
              );
            })}
          </div>
        </div>
        <div className="w-full bg-[#E2E2E2] rounded-3xl border border-black py-4 sm:bg-transparent sm:border-none sm:py-0 sm:w-2/5 px-4">
          <IconImageBadge
            profileImage={profileImage ? profileImage : userData?.image ? userData.image : ''}
            title={
              studentProfile?.firstname
                ? studentProfile.firstname
                : editNameInPortal?.enabled
                ? getTranslation(t, 'class.student.editName.enterName')
                : ''
            }
            allowEditName={editNameInPortal?.enabled}
            onEditName={() => setEditName(true)}
          />
        </div>
      </div>
      <div className="my-6">
        <ButtonNext
          disabled={isEmpty(profileImage)}
          title={getTranslation(t, 'class.student.student_login_data.page_1.button_content')}
          handler={onContinue}
        />
      </div>

      <Popup
        open={editName}
        onClose={() => setEditName(false)}
        contentStyle={{
          borderRadius: '20px',
          padding: '20px'
        }}>
        <h2
          className="text-xl md:text-2xl font-semibold text-black-default"
          dangerouslySetInnerHTML={{
            __html: getTranslation(t, 'class.student.editName.title')
          }}
        />
        <p
          className="text-sm md:text-lg text-cblack-default mt-2"
          dangerouslySetInnerHTML={{
            __html: getTranslation(t, 'class.student.editName.description')
          }}
        />

        <form
          onSubmit={(e) => {
            e.preventDefault();

            setEditName(false);
          }}
          className="relative mt-4 flex items-center gap-3 justify-center flex-wrap sm:flex-nowrap">
          <span className="block w-6">
            <BiEdit className="text-lg" />
          </span>
          <input
            className="border-2 border-cwhite-500 px-4 py-2 rounded-lg bg-cwhite-500 sm:w-full"
            placeholder={'pseudonym'}
            value={studentProfile.firstname}
            onChange={(e) => setStudentProfile({ ...studentProfile, firstname: e.target.value })}
          />
          <div className="my-6 flex justify-center">
            <ButtonNext
              type={'submit'}
              title={getTranslation(t, 'class.student.editName.submit')}
              className={'w-full'}
            />
          </div>
        </form>
      </Popup>
    </section>
  );
}
