import { createContext, useState } from 'react';

export const AdminContext = createContext();

export const AdminProvider = (props) => {
  const [projectID, setProjectID] = useState(); // org db id
  const [themeId, setThemeId] = useState();
  const [teamType, setTeamType] = useState(); // school || team
  const [studentProfile, setStudentProfile] = useState();
  const [themeConfig, setThemeConfig] = useState(null);
  const [portalConfig, setPortalConfig] = useState(null);

  return (
    <AdminContext.Provider
      value={{
        projectID,
        setProjectID,
        themeId,
        setThemeId,
        teamType,
        setTeamType,
        studentProfile,
        setStudentProfile,
        themeConfig,
        setThemeConfig,
        portalConfig,
        setPortalConfig
      }}>
      {props.children}
    </AdminContext.Provider>
  );
};
