import React, { useContext } from 'react';
import { MdKeyboardBackspace } from 'react-icons/md';

import { AdminContext } from '../../../contexts/admin.context';

export default function FloatingBackButton({ className, onGoBack }) {
  const { themeConfig: { primary } = {} } = useContext(AdminContext);

  return (
    <button
      className={`p-1 rounded-md ${className}`}
      style={{ backgroundColor: primary }}
      onClick={onGoBack}>
      <svg
        className="w-3 h-3"
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="11"
        viewBox="0 0 10 11"
        fill="transparent">
        <path d="M7.5 10L3 5.5L7.5 1" stroke="white" strokeWidth="2" />
      </svg>
    </button>
  );
}
