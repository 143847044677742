import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { AdminContext } from '../../contexts/admin.context';

import { FloatingBackButton } from '../../components/common';
import StepBar from '../../components/common/StepBar';

import { useTranslation } from 'react-i18next';
import { getTranslation } from '../../util/translation';
import InputSelectStudent from '../../components/common/InputSelectStudent';
import ButtonNext from '../../components/ui/button/ButtonNext';

export default function StudentProfileStep3() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedLanguage, _setSelectedLanguage] = useState({});

  const {
    portalConfig: { basePath, languages } = {},
    studentProfile,
    setStudentProfile
  } = useContext(AdminContext);

  const [languageOptions] = useState(
    languages.map((lang) => ({ label: lang.toUpperCase(), value: lang }))
  );

  useEffect(() => {
    if (isEmpty(studentProfile.roles)) navigate(basePath + '/student-profile-3');
    if (studentProfile.language) {
      const lang = languageOptions.find((lang) => lang.value === studentProfile.language);
      _setSelectedLanguage(lang);
    }
  }, [studentProfile]);

  const onGoBack = () => {
    navigate(basePath + '/student-profile-3');
  };

  const setSelectedLanguage = (lang) => {
    _setSelectedLanguage(lang);
    setStudentProfile({ ...studentProfile, language: lang.value });
  };

  const onContinue = () => {
    setStudentProfile({ ...studentProfile, language: selectedLanguage.value });
    navigate(basePath + '/student-profile-5');
  };

  return (
    <section>
      <FloatingBackButton className="block" onGoBack={onGoBack} />
      <div className="max-w-md mx-auto">
        <StepBar className="w-full my-8" stepsClass="w-full" activeStep={4} />

        <h2
          className="text-xl md:text-2xl font-semibold text-black-default"
          dangerouslySetInnerHTML={{
            // __html: getTranslation(t, 'class.student.student_login_data.page_3.step_2')
            __html: 'Select Prefered Language'
          }}
        />
        <p
          className="text-sm md:text-lg text-cblack-default"
          dangerouslySetInnerHTML={{
            // __html: 'class.student.student_login_data.page_3.description'
            __html: 'Select the language you are most comfortable with.'
          }}
        />

        <div className="my-4">
          {languageOptions?.length > 1 && (
            <InputSelectStudent
              value={selectedLanguage}
              options={languageOptions}
              onChange={setSelectedLanguage}
              containerClassName={'!m-0'}
              className={'!py-2 !m-0'}
            />
          )}
          {selectedLanguage && (
            <div className="text-left my-6">
              <ButtonNext
                title={getTranslation(t, 'class.student.student_login_data.page_2.button_content')}
                className="ml-auto mr-auto md:ml-0 md:mr-auto"
                handler={onContinue}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
