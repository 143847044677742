export const availableLanguages = ['en', 'fr', 'de', 'it'];

let orgPrefix = '';
let teamType = '';
let region = '';

export const setOrgPrefix = (prefix) => {
  orgPrefix = prefix;
};

export const setTranslationTeamType = (type) => {
  teamType = type;
};

export const setRegion = (reg) => {
  region = reg;
};

export const getTranslation = (t, key, array = false, index = 0, subKey = '') => {
  let translation = t(`${teamType}.${key}`, { returnObjects: true });

  if (array) translation = translation?.[index]?.[subKey] ?? translation?.[index];

  const returningTranslation = translation?.[region]
    ? translation?.[region]
    : translation?.[orgPrefix]
    ? translation?.[orgPrefix]
    : translation?.default;

  return returningTranslation && returningTranslation !== ''
    ? returningTranslation
    : `${teamType}.${key}`?.toString();
};

export const getSortedList = (list, lang) => {
  return [...list]
    .filter((item) => item?.[`name_${lang}`])
    .sort((a, b) => a?.[`name_${lang}`].localeCompare(b?.[`name_${lang}`], lang));
};
