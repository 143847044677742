import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { AdminContext } from '../../contexts/admin.context';

import { DropdownListSelector, FloatingBackButton, IconImageBadge } from '../../components/common';
import StepBar from '../../components/common/StepBar';
import ButtonNext from '../../components/ui/button/ButtonNext';

import { DropdownListDisplay } from '../../components/common/DropdownListSelector';

import { useTranslation } from 'react-i18next';
import { getTranslation } from '../../util/translation';

export default function StudentProfileStep3() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    portalConfig: { basePath, roles } = {},
    studentProfile,
    setStudentProfile
  } = useContext(AdminContext);

  const [forceSelections, setForceSelections] = useState([]);
  const [showFinalList, setShowFinalList] = useState(false);

  useEffect(() => {
    if (isEmpty(studentProfile.strengths)) navigate(basePath + '/student-profile-2');

    if (studentProfile.roles) {
      setForceSelections(studentProfile.roles);
      setShowFinalList(true);
    }
  }, [studentProfile]);

  const onContinue = () => {
    setStudentProfile({ ...studentProfile, roles: forceSelections });
    navigate(basePath + '/student-profile-5');
    // navigate(basePath + '/student-profile-4');
  };

  const onChangeForceSelections = (option) => {
    const index = forceSelections.findIndex((item) => item._id === option._id);
    if (index === -1 && forceSelections.length < 3) {
      setForceSelections([...forceSelections, option]);
      if (forceSelections.length === 2) {
        setShowFinalList(true);
      }
    } else if (index !== -1) {
      const updatedSelections = [...forceSelections];
      updatedSelections.splice(index, 1);
      setForceSelections(updatedSelections);
      setShowFinalList(false);
    }
  };

  const onShowDropdown = () => {
    setShowFinalList(!showFinalList);
  };

  const onGoBack = () => {
    navigate(basePath + '/student-profile-2');
  };

  return (
    <section>
      <FloatingBackButton className="block" onGoBack={onGoBack} />

      <div className="max-w-md mx-auto">
        <StepBar className="w-full my-8" stepsClass="w-full" activeStep={3} />

        <h2
          className="text-xl md:text-2xl font-semibold text-black-default"
          dangerouslySetInnerHTML={{
            __html: getTranslation(t, 'class.student.student_login_data.page_3.step_3')
          }}
        />
        <p
          className="text-sm md:text-lg text-cblack-default"
          dangerouslySetInnerHTML={{
            __html: getTranslation(t, 'class.student.student_login_data.page_3.description')
          }}
        />

        <div className="my-4">
          {!showFinalList && (
            <DropdownListSelector
              options={roles}
              onClick={onChangeForceSelections}
              selectedOption={forceSelections}
            />
          )}

          {showFinalList && (
            <div className="my-6 bg-cwhite-200 rounded-lg p-4 mx-auto">
              <DropdownListDisplay list={forceSelections} onClick={onShowDropdown} />
            </div>
          )}

          {showFinalList && (
            <div className="text-left my-6">
              <ButtonNext
                title={getTranslation(t, 'class.student.student_login_data.page_3.button_content')}
                className="ml-auto mr-auto md:ml-0 md:mr-auto"
                handler={onContinue}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
