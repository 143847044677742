import React, { useContext, useEffect, useState } from 'react';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import {
  useParams,
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useNavigate,
  Outlet
} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { AdminContext } from './contexts/admin.context';

import {
  StudentLogin,
  StudentProfileStep1,
  StudentProfileStep2,
  StudentProfileStep3,
  StudentProfileStep4,
  StudentProfileStep5
} from './pages/student';

import { DashboardLayout, FullScrenLoader } from './components';

import { fetchTranslations, getPortalDetails, setOrgHeaders } from './service';
import { getSortedList, setOrgPrefix, setTranslationTeamType } from './util/translation';

import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [translationsLoading, setTranslationsLoading] = useState(true);

  const { projectID, themeId: theme, teamType: team } = useContext(AdminContext);
  const basePath = `/${theme}/${team}`;

  useEffect(() => {
    if (!translationsLoading) return;

    i18next.init({
      interpolation: { escapeValue: false },
      returnEmptyString: false
    });
    i18next.languages = ['en', 'fr', 'de', 'it'];
    i18next.use(initReactI18next).init({
      fallbackLng: 'en',
      lng: 'fr',
      ns: ['translations'],
      defaultNS: 'translations'
    });

    const loadTranslations = async () => {
      const { data: { en, de, fr, it } = {} } = await fetchTranslations();

      i18next.addResourceBundle('en', 'translations', en);
      i18next.addResourceBundle('fr', 'translations', fr);
      i18next.addResourceBundle('de', 'translations', de);
      i18next.addResourceBundle('it', 'translations', it);

      let oldLang = '';
      try {
        const searchParams = new URLSearchParams(window.location.search);
        oldLang = searchParams.get('lang') ?? localStorage.getItem('lang');
      } catch (error) {
        console.log('LS unavailable', error);
      }

      if (['en', 'fr', 'de', 'it'].includes(oldLang)) {
        i18next.changeLanguage(oldLang);
      } else {
        i18next.changeLanguage('fr'); // fallback
      }

      setTranslationsLoading(false);
    };

    loadTranslations();
  }, [translationsLoading]);

  if (translationsLoading) return <FullScrenLoader />;

  return (
    <>
      <ToastContainer hideProgressBar />
      <Router>
        <Routes>
          <Route path="/:orgPrefix/:team" element={<BaseContainer />}>
            <Route path="login" element={<StudentLogin projectID={projectID} />} />

            {/* Student Dashboard Pages */}
            <Route path="" element={<DashboardLayout basePath={basePath} />}>
              <Route path="student-profile-1" element={<StudentProfileStep1 />} />
              <Route path="student-profile-2" element={<StudentProfileStep2 />} />
              <Route path="student-profile-3" element={<StudentProfileStep3 />} />
              <Route path="student-profile-4" element={<StudentProfileStep4 />} />
              <Route path="student-profile-5" element={<StudentProfileStep5 />} />
            </Route>
          </Route>

          {/* Not found */}
          <Route path="/not-found" element={<NotFound />} />
          <Route path="/" element={<Navigate replace to="/fr/school/login" />} />
          <Route path="*" element={<Navigate replace to="/not-found" />} />
        </Routes>
      </Router>
    </>
  );
}

const BaseContainer = () => {
  const navigate = useNavigate();

  const { i18n } = useTranslation();

  const { setProjectID, setThemeConfig, setThemeId, setTeamType, setPortalConfig } =
    useContext(AdminContext);

  const { orgPrefix, team } = useParams();

  const [load, setLoad] = useState(false);
  const [showLanguage, setShowLanguage] = useState('');

  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const {
          data: {
            masterOrg: {
              _id,
              customConfigurations,
              favicon,
              schoolFavicon,
              teamFavicon,
              languages,
              teacherPortalHTMLTitle,
              logo,
              schoolLogo,
              teamLogo
            } = {},
            portalDetails,
            roles,
            strengths,
            service: { name, isActive, hasClassStudentSetup } = {}
          } = {}
        } = await getPortalDetails(orgPrefix, team);

        // if (!hasClassStudentSetup) {
        //   navigate(`/not-found`);
        //   return;
        // }

        // Check if corrent language is applied
        const lang = i18n.language;
        if (!languages.includes(lang)) {
          setShowLanguage(languages[0]);
        }

        let selectedFavicon = '';

        if (team === 'school' && schoolFavicon) {
          selectedFavicon = schoolFavicon;
        } else if (team === 'team' && teamFavicon) {
          selectedFavicon = teamFavicon;
        } else {
          selectedFavicon = favicon;
        }

        if (selectedFavicon) {
          changeFavicon(process.env.REACT_APP_AWS_URL + selectedFavicon);
        }

        if (teacherPortalHTMLTitle) document.title = teacherPortalHTMLTitle;

        setProjectID(_id);

        setThemeConfig({
          ...customConfigurations?.colors?.mf,
          buttonRadius: customConfigurations?.buttonRadius ?? '0px'
        });

        setPortalConfig({
          ...portalDetails,
          basePath: `/${orgPrefix}/${team}`,
          name,
          languages,
          logo,
          schoolLogo,
          teamLogo,
          roles: getSortedList(roles, i18n.language),
          strengths: getSortedList(strengths, i18n.language),
          isActive,
          hasClassStudentSetup
        });
        setThemeId(orgPrefix);
        setTeamType(team);

        // For translation
        setOrgPrefix(orgPrefix);
        setTranslationTeamType(team);

        // For api
        setOrgHeaders(orgPrefix, team);

        // Direct teacher api url set
        // psyfiersAxios.defaults.baseURL = portalDetails?.serverBaseUrl;

        setLoad(true);
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data || 'Portal not found');
        navigate(`/not-found`);
      }
    })();
  }, [orgPrefix, team, i18n.language]);

  useEffect(() => {
    if (showLanguage) {
      i18n.changeLanguage(showLanguage);
    }
  }, [showLanguage]);

  if (!load) return <FullScrenLoader />;

  return <Outlet />;
};

const NotFound = () => {
  return (
    <div className="z-50 backdrop-blur-sm fixed top-0 left-0 w-full h-full flex items-center justify-center">
      <p className="text-black text-2xl">Not found</p>
    </div>
  );
};

export default App;
