import React, { useContext } from 'react';
import SlideContainer from './SlideContainer';
import SlideList from './SlideList';

import { AdminContext } from '../../../contexts/admin.context';

export default function Slide3({
  roles = [],
  title = 'Mes 3 rôles professionnels',
  buttonText = 'Modifier'
}) {
  const { portalConfig: { basePath } = {} } = useContext(AdminContext);

  return (
    <SlideContainer title={title} buttonText={buttonText} link={basePath + '/student-profile-3'}>
      <SlideList items={roles} />
    </SlideContainer>
  );
}
