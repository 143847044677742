import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { AdminContext } from '../../../contexts/admin.context';

export default function SlideContainer({ title, buttonText = 'Modifier', link, children }) {
  const { themeConfig: { primary, buttonRadius } = {} } = useContext(AdminContext);

  return (
    <div className="w-full px-12 mx-auto rounded-md py-6 text-center overflow-x-hidden">
      <div className="flex flex-col justify-center h-full">
        <p className={`font-semibold text-xl`} style={{ color: primary }}>
          {title}
        </p>
        <div className="">{children}</div>
        <Link
          to={link}
          className="border-[1px] w-fit mx-auto border-black text-cblack-default font-medium px-8 py-2 rounded"
          style={{ borderRadius: buttonRadius, borderColor: primary, color: primary }}>
          {buttonText}
        </Link>
      </div>
    </div>
  );
}
