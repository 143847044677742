import React, { useContext } from 'react';

import { BsCircleFill } from 'react-icons/bs';

import { AdminContext } from '../../../contexts/admin.context';

export default function StepBar({
  className = '',
  stepsClass = 'w-full',
  activeStep = 1,
  totalSteps = 4
}) {
  const { themeConfig: { primary } = {} } = useContext(AdminContext);

  return (
    <div className={className}>
      <ul className={`flex items-center ${stepsClass}`}>
        {new Array(totalSteps).fill(0).map((_, index) => {
          let circleTextClass = activeStep === index + 1 ? 'text-xl text-2xl' : 'text-xs text-sm';
          let circleColorClass = 'text-cwhite-500';

          if (index === totalSteps - 1)
            return (
              <BsCircleFill
                key={index}
                className={`${circleTextClass} ${circleColorClass}`}
                style={{ color: activeStep >= index + 1 ? primary : '' }}
              />
            );

          return (
            <li
              key={index}
              className={`${stepsClass.includes('w-full') ? 'w-1/3' : 'w-10 sm:w-20'}`}>
              <div className="flex items-center">
                <BsCircleFill
                  className={`${circleTextClass} ${circleColorClass}`}
                  style={{ color: activeStep >= index + 1 ? primary : '' }}
                />
                {totalSteps !== index + 1 ? (
                  <div
                    className={`w-full`}
                    style={{
                      borderWidth: '1px',
                      borderStyle: 'dashed',
                      borderColor: activeStep >= index + 1 ? primary : ''
                    }}></div>
                ) : (
                  ''
                )}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
