import React, { useContext } from 'react';

import { AdminContext } from '../../contexts/admin.context';
import { BiEdit } from 'react-icons/bi';

export default function IconImageBadge({ title, profileImage, allowEditName, onEditName }) {
  const { themeConfig: { primary } = {} } = useContext(AdminContext);

  return (
    <div className="flex flex-col justify-end h-full">
      <div className="flex items-center justify-center gap-2">
        <h2 className="text-black text-center text-2xl font-bold mb-2">{title}</h2>
        {allowEditName && (
          <button
            className="text-base cursor-pointer text-black px-2 py-1 rounded-full"
            onClick={onEditName}>
            <BiEdit />
          </button>
        )}
      </div>
      <div
        className="h-60 w-60 overflow-hidden mx-auto rounded-full p-2"
        style={{ background: `linear-gradient(180deg, #E2E2E2, ${primary})` }}>
        {profileImage && (
          <img
            src={profileImage}
            className="w-full h-full object-cover object-center"
            alt="title"
          />
        )}
      </div>
    </div>
  );
}
