import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AdminProvider } from './contexts/admin.context';
import './index.css';
import 'reactjs-popup/dist/index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <AdminProvider>
      <App />
    </AdminProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
