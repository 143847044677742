import React, { useContext } from 'react';
import { AdminContext } from '../../../contexts/admin.context';

export default function ButtonOutlined({ className, children, handler, disabled }) {
  const { themeConfig: { primary, buttonRadius } = {} } = useContext(AdminContext);

  return (
    <button
      className={`px-4 py-2 rounded font-bold border ${className} disabled:opacity-60`}
      style={{
        backgroundColor: 'transparent',
        borderColor: primary,
        borderRadius: buttonRadius,
        color: primary
      }}
      disabled={disabled}
      onClick={() => handler()}>
      {children}
    </button>
  );
}
