import React, { useContext } from 'react';
import { Spinner } from '../../common';
import { AdminContext } from '../../../contexts/admin.context';

export default function ButtonBasic({ onClick, className, children, loading, ...rest }) {
  const { themeConfig: { primary, buttonRadius } = {} } = useContext(AdminContext);

  return (
    <button
      className={`text-white px-4 py-2 rounded font-bold flex items-center justify-center ${className} disabled:opacity-60`}
      style={{ backgroundColor: primary, borderRadius: buttonRadius }}
      disabled={loading}
      onClick={onClick}
      {...rest}>
      {children} <Spinner isSubmitting={loading} />
    </button>
  );
}
