import React, { useContext } from 'react';
import SlideContainer from './SlideContainer';

import { AdminContext } from '../../../contexts/admin.context';
import SlideList from './SlideList';

export default function Slide2({
  forces = [],
  title = 'Mes 3 forces préférées',
  buttonText = 'Modifier'
}) {
  const { portalConfig: { basePath } = {} } = useContext(AdminContext);

  return (
    <SlideContainer title={title} buttonText={buttonText} link={basePath + '/student-profile-2'}>
      <SlideList items={forces} />
    </SlideContainer>
  );
}
