import React from 'react';
import { FaRedoAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

export default function DropdownListDisplay({ list, onClick }) {
  const { i18n } = useTranslation();

  return (
    <ul>
      {list.map((item, index) => {
        return (
          <li
            key={index}
            className="mb-2 border-b-2 border-cwhite-400 py-2 cursor-pointer"
            onClick={onClick}>
            <div className="flex items-center justify-between">
              <span>{item?.[`name_${i18n.language}`]}</span>
              <span>
                <FaRedoAlt />
              </span>
            </div>
          </li>
        );
      })}
    </ul>
  );
}
