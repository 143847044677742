import ClipLoader from 'react-spinners/ClipLoader';

const Spinner = ({ isSubmitting = false, color = '#FFF', setSize = 16 }) =>
  isSubmitting ? (
    <ClipLoader color={color} loading={true} size={setSize} className="ml-2" />
  ) : (
    <></>
  );

export default Spinner;
